import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { GitPullRequest, LogIn, LogOut } from "react-feather";

import { TransferContext } from "../../context/transfer-context";
import { isEmpty } from "lodash";

const CreateTransfer = (props) => {
  registerLocale('ru', ru);

  const { show } = props;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const state = useContext(TransferContext);
  
  useEffect(() => {
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateField = async (event) => {
    await state.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setError(null);
    setLoading(true);
    await state.createTransfer()
    .then((data) => {
      props.togglePopUp();
      setLoading(false);
    })
    .catch((error) => {
      setError(error.message);
      setLoading(false);
    });
  }

  return (
    <>
      <div
        className={`modal fade ${show ? "show" : "d-none"}`}
        tabIndex="-1"
        style={{ overflow: "scroll" }}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Создать перемещение средств
              </h5>
              <button
                type="button"
                onClick={props.togglePopUp}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span style={{ color: "white" }} aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            {loading ? (
              <div className="placeholder-glow my-4 text-center">
                {Array.from({ length: 4 }).map((_, idx) => (
                  <div key={idx}>
                    <span
                      className="placeholder w-75 rounded-pill my-4"
                      style={{ height: "40px" }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="modal-body text-white">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className={`${
                        state.type === "INCOME"
                          ? "box-green"
                          : state.type === "OUTCOME"
                          ? "box-red"
                          : "box"
                      }`}
                    >
                      {isEmpty(state.type) && <h4 className="text-center my-4">Новое перемещение <GitPullRequest /></h4>}
                      {state.type === 'INCOME' && <h4 className="text-center text-success my-4">Новый приход <LogIn /></h4>}
                      {state.type === 'OUTCOME' && <h4 className="text-center text-danger my-4">Новый расход <LogOut /></h4>}

                      <div className="form-group">
                        <label
                          style={{ fontSize: 16, color: "#fff" }}
                          htmlFor="type"
                        >
                          <strong>1. Тип перемещения:</strong>
                        </label>
                        <div className="select-wrapper">
                          <select
                            onChange={updateField}
                            className="form-control"
                            name="type"
                            id="type"
                            value={state.type || ""}
                          >
                            <option value="">Приход или расход?</option>
                            <option value="INCOME">Приход</option>
                            <option value="OUTCOME">Расход</option>
                          </select>
                        </div>
                      </div>

                      {!isEmpty(state.users) && (
                        <div className="form-group">
                          <label
                            style={{ fontSize: 16, color: "#fff" }}
                            htmlFor="user_id"
                          >
                            <strong>2. Выберите пользователя:</strong>
                          </label>
                          <div className="select-wrapper">
                            <select
                              onChange={updateField}
                              className="form-control"
                              name="user_id"
                              id="user_id"
                              value={state.user_id || ""}
                            >
                              <option value="">Все пользователи</option>
                              {state.users.map((user, index) => (
                                <option key={index} value={user.id}>
                                  {user.fullname} - {user.currency}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      
                      <div className="form-group">
                        <label
                          style={{ fontSize: 16, color: "#fff" }}
                          htmlFor="date"
                        >
                          <strong>3. Выберите дату для остатка:</strong>
                        </label>
                        <DatePicker
                          selected={state.date ? new Date(state.date) : null}
                          placeholderText="Выберите дату"
                          wrapperClassName="form-control"
                          autoComplete="off"
                          className="form-control"
                          onChange={(date) => state.updateState("date", date)}
                          dateFormat="yyyy-MM-dd"
                          locale="ru"
                          id="date"
                        />
                      </div>

                      <div className="form-group">
                        <label
                          style={{ fontSize: 16, color: "#fff" }}
                          htmlFor="amount"
                        >
                          <strong>4. Сумма { state.type === "INCOME" ? "прихода" : state.type === "OUTCOME" ? "расхода" : ""}:</strong>
                        </label>
                        <input
                          type="number"
                          onChange={updateField}
                          className="form-control"
                          name="amount"
                          id="amount"
                          placeholder="Введите сумму"
                          value={state.amount || ""}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          style={{ fontSize: 16, color: "#fff" }}
                          htmlFor="comment"
                        >
                          <strong>5. Комментарий:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={updateField}
                          className="form-control"
                          name="comment"
                          id="comment"
                          placeholder="Введите комментарий"
                          value={state.comment || ""}
                        />
                      </div>
                    </div>
                    {error && (
                      <div className="alert alert-danger mt-4" role="alert">
                        {error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer pb-0">
              <button
                onClick={props.togglePopUp}
                type="button"
                data-dismiss="modal"
                style={{ fontSize: "14px" }}
                className="bg-secondary btn-line-white text-white py-2 px-4 fw-bold mt-1 rounded-3 border-0"
              >
                Закрыть
              </button>
              <button
                onClick={() => submit()}
                style={{ fontSize: "14px" }}
                className="bg-success btn-line-white text-white py-2 px-5 fw-bold mt-1 rounded-3 border-0"
              >
                Создать
                <GitPullRequest className="link-icon ms-2 mb-1" size={15} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTransfer;