import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { isEmpty, orderBy } from "lodash";
import { evaluate } from "mathjs";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import { UserContext } from "../../context/users-context";
import {AlignJustify} from "react-feather";
import { Search } from "react-feather";
import moment from "moment";

const UsersStats = (props) => {
  
  const [loading, setLoading] = React.useState(false);

  registerLocale('ru', ru);
  const userCunsumer = useContext(UserContext);

  useEffect(() => {
    return function cleanup() {
      userCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const submit = async () => {
    return userCunsumer
      .getUserStatsWeb()
      .then(() => sendNotification("success", "Успех", "Запрос отправлен."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Статистика по пользователям
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="box d-flex">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="comment">
                        <strong>Дана начала:</strong>
                      </label>
                      <DatePicker 
                        selected={userCunsumer.start ? new Date(userCunsumer.start) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        className="form-control"
                        onChange={(date) => {
                          userCunsumer.updateState('start', moment(date).format('YYYY-MM-DD'));
                        }}
                        dateFormat='yyyy-MM-dd' 
                        // openToDate={new Date()}
                        locale="ru"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="comment">
                        <strong>Дана финиш 🏁:</strong>
                      </label>
                      <DatePicker 
                        selected={userCunsumer.end ? new Date(userCunsumer.end) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        className="form-control"
                        onChange={(date) => {
                          userCunsumer.updateState('end', moment(date).format('YYYY-MM-DD'))
                        }}
                        dateFormat='yyyy-MM-dd' 
                        // openToDate={new Date()}
                        locale="ru"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-3 pt-1">
                    {loading === true ? (
                      <button className="btn btn-line-info btn-small mt-4">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Загрузка...
                      </button>  
                    ) : (
                      <button
                        onClick={(e) => submit(e)}
                        className={`bg-success btn-line-white text-white fw-bold px-3 py-1 mt-2 rounded-3 border-0`}
                      >
                        Получить <Search className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>

            <div className="row mt-5">
              { loading ? <Loader /> : (
                <>
                  
                  {!isEmpty(userCunsumer.data) && (
                    <>
                      {
                        orderBy(userCunsumer.data.stats, ['amount'], ['desc']).map((item, index) => {
                          return (
                            <div className="col-md-3 stretch-card mb-3" key={item.user}>
                              <div className="card p-3">
                                <h6 className="d-flex justify-content-between">
                                  {item.user}
                                  {index === 0 && <span>ТОП 1: 🏆</span>}
                                  {index === 1 && <span>ТОП 2: 🥈</span>}
                                  {index === 2 && <span>ТОП 3: 🥉</span>}
                                </h6>
                                <hr className="mb-2 mt-3" />
                                <p className="text-white mb-0">Выплачено: {item.amount} руб.</p>
                                <p className="text-white mb-0">Количество заявок: {item.count} шт.</p>
                                <p className="text-white mb-0">Средний чек: {item.avg} руб.</p>
                                <p className="text-white mb-0">Средний курс: {item.avg_course} руб.</p>
                                <hr className="mb-2 mt-3" />
                                <p className="text-success mb-0 fw-bold">Заработано: {evaluate(`(${item.count} * 50) + (${item.amount * item.comission})`).toFixed(2)} руб.</p>
                                <p className="text-muted mb-0">({item.count} * 50) + ({item.amount} * {item.comission})</p>
                              </div>
                            </div>
                          );
                        })
                      }
                    </>
                  )}

                  {!isEmpty(userCunsumer?.data?.totals) && (
                    <div className="col-md-3 stretch-card mb-3">
                      <div className="card p-3" style={{ backgroundColor: "rgb(8 47 3)"}}>
                        <h6 className="d-flex justify-content-between">
                          Всего вместе:
                        </h6>
                        <hr className="mb-2 mt-3" />
                        <p className="text-white mb-0 fw-bold">Выплачено: {userCunsumer.data.totals.total_amount} руб.</p>
                        <p className="text-white mb-0 fw-bold">Количество заявок: {userCunsumer.data.totals.total_count} шт.</p>
                        <p className="text-white mb-0 fw-bold">Общий средний курс: {userCunsumer.data.totals.avg_course} руб.</p>
                        <p className="text-white mb-0 fw-bold">Общий средний чек: {userCunsumer.data.totals.total_avg_amount} руб.</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersStats;