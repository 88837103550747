import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { FilePlus } from "react-feather";
import { filter } from "lodash";

import { RemainContext } from "../../context/remain-context";


const CreateMassRemain = (props) => {
  registerLocale('ru', ru);

  const { show } = props;

  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [status, setStatus] = React.useState('');

  const remainState = useContext(RemainContext);
  
  useEffect(() => {
    async function fetchUsers() {
      const filteredUsers = filter(remainState?.data?.remains, { type: 'END' });
      setUsers(filteredUsers);
      setLoading(false);
    }
    
    if (show && remainState?.data?.remains) {
      fetchUsers();
    }

    return () => {
      setUsers([]);
      setStatus('');
      setLoading(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, remainState?.data?.remains]);

  const submit = async () => {
    const confirmed = window.confirm("Вы правильно выбрали дату для создания остатка утро?");

    if (!confirmed) {
      return;
    }  

    await remainState.setUserUpdateProcess(users);

    for (const user of users) {
      await remainState.massCreateRemain(user.user_id, remainState.date, 'START', user.ballance);
    }
    
    await setStatus(`Все запросы отправлены ✅`);
  }

  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        style={{overflow: 'scroll'}}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content  p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Создание массового остатка утро
              </h5>
              <button
                type="button"
                onClick={props.togglePopUp}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span style={{color: 'white'}} aria-hidden="true">&times;</span>
              </button>
            </div>
            {loading ? (
              <div className="placeholder-glow my-4 text-center">
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
              </div>
            ) : (
              <div className="modal-body text-white">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="date">
                        <strong>1. На какой день создаем массовый остаток?</strong>
                      </label>
                      <DatePicker 
                        selected={remainState.date ? new Date(remainState.date) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        className="form-control"
                        autoComplete="off"
                        onChange={(date) => { remainState.updateState('date', date); }}
                        dateFormat='yyyy-MM-dd'
                        locale="ru"
                        id="date"
                        filterDate={(date) => {
                          const day = date.getDay();
                          return day === 1;
                        }}
                        dayClassName={(date) => {
                          const day = date.getDay();
                          return day === 1 && 'monday-highlight';
                        }}
                      />
                    </div>

                    {remainState.date && users && (
                      <div className="mt-4">
                        <h6>Будет создано для таких пользователей:</h6>
                        <p className="text-muted">(сформировано на основе активного остатка вечер)</p>
                        <table className="table table-hover table-bordered">
                          <tbody>
                            {users.map((user, index) => (
                              <tr key={index}>
                                <td>{user.username}</td>
                                <td>{user.ballance} {user.currency}</td>
                                {/* <td>
                                  {remainState.usersUpdateProcess?.[user.user_id] === 'loading' && (
                                    <div class="spinner-border text-light" role="status">
                                      <span class="visually-hidden">Loading...</span>
                                    </div>
                                  )}

                                  {remainState.usersUpdateProcess?.[user.user_id] === 'done' && (
                                    <span>✅</span>
                                  )}
                                </td> */}
                                <td>
                                  {(() => {
                                    const status = remainState.usersUpdateProcess?.[user.user_id];
                                    switch(status) {
                                      case 'loading':
                                        return <span className="rotating-icon fs-18">🔄</span>;
                                      case 'done':
                                        return <span className="fs-18">✅</span>;
                                      case 'error':
                                        return <span className="fs-18">❌</span>;
                                      default:
                                        return null;
                                    }
                                  })()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {status && (
                      <div className="mt-4 text-center">
                        <p className="text-success fw-bold">
                          {status}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            
            <div className="modal-footer pb-0">
              <button
                onClick={props.togglePopUp}
                type="button"
                data-dismiss="modal"
                style={{ fontSize: '14px' }}
                className={`bg-secondary btn-line-white text-white py-2 px-4 fw-bold mt-1 rounded-3 border-0`}
              >
                Закрыть
              </button>
              <button
                onClick={() => submit()}
                style={{ fontSize: '14px' }}
                className={`bg-success btn-line-white text-white py-2 px-5 fw-bold mt-1 rounded-3 border-0`}
              >
                Создать <FilePlus className="link-icon ms-1 mb-1" size={15} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMassRemain;