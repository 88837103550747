import React, {useEffect} from "react";

import Loader from "../../components/Loader";
import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";
import { OrderContext } from "../../context/orders-context";
import { CreditCard, AlignJustify, FilePlus, Plus } from "react-feather";
import { isEmpty } from "lodash";
import { sendNotification } from "../../helpers/notification";

const UserHome = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [startBallance, setStartBallance] = React.useState();
  const orderCunsumer = React.useContext(OrderContext);
  const userCunsumer = React.useContext(UserContext);

  React.useEffect(() => {
    userCunsumer.getOrderTotals();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isEmpty(localStorage.getItem("startBallance"))) {
      setStartBallance(localStorage.getItem("startBallance"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hendler = async (event) => {
    await orderCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    orderCunsumer.createOrder().then(() => {
      userCunsumer.getOrderTotals();
      setLoading(false);
    });
  };

  const startShift = async () => {
    if (isEmpty(orderCunsumer.startBallance)) {
      sendNotification("warning", "Ошибка ⚠️", "Введите баланс на начало смены.");
      return;
    }

    orderCunsumer.startShift(orderCunsumer.startBallance).then(() => {
      setStartBallance(orderCunsumer.startBallance);
      localStorage.setItem("startBallance", orderCunsumer.startBallance);
      sendNotification("success", "Успех", "Вы начали смену.");
    }).catch((error) => {
      sendNotification("danger", "Ошибка", JSON.parse(error.response.text).message);
    });
  };

  const endShift = async () => {
    if (isEmpty(orderCunsumer.endBallance)) {
      sendNotification("warning", "Ошибка ⚠️", "Введите баланс на конец смены.");
      return;
    }

    if (isEmpty(orderCunsumer.screenBalance)) {
      sendNotification("warning", "Прикрепите скрин", "Вы забыли прикрепить скриншот.");
      return;
    }

    orderCunsumer.endShift(orderCunsumer.endBallance).then((res) => {
      if (res.body.status === 'success') {
        let { startTime, startBallance, endBallance, email, turnover_rub, turnover_usdt, total_db_rub } = res.body;
        setStartBallance(null);
        localStorage.removeItem("startBallance");
        sendNotification("success", "Успех", "Вы Закончили смену.");
        orderCunsumer.adminNotify({ startTime, startBallance, endBallance, email, turnover_rub, turnover_usdt, total_db_rub });
      }
    }).catch((error) => {
      sendNotification("danger", "Ошибка", JSON.parse(error.response.text).message);
    });
  };



  useEffect(() => {
    // setLoading(true);
    // userCunsumer.getCurrency();
    return function cleanup() {
      userCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isEmpty(userCunsumer.currency)) {
      setLoading(false)
    }
  }, [userCunsumer.currency]);

  return (
    <>
      {loading && <p>Loading</p>}
      <div className="main-wrapper">
        <UserBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Создать заявку
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              {isEmpty(localStorage.getItem("startBallance")) && (
                <div className="col-12 col-md-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="startBallance" style={{fontSize: 14, color: '#fff'}}>
                          <b>Баланс на начало смены:</b>
                        </label>
                        <input
                          type="text"
                          name="startBallance"
                          onChange={hendler}
                          placeholder="введите сумму"
                          required
                          className="form-control"
                          id="startBallance"
                        />
                      </div>
                      <button
                        className={`bg-primary text-white px-3 py-1 mt-2 rounded-3`}
                        style={{ border: "none", fontSize: 14 }}
                        onClick={() => startShift()}
                      >
                        Начать смену 👉
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`row justify-content-start ${isEmpty(startBallance) ? 'd-none' : 'd-flex'}`}>
              <div className="col-12 col-md-6 col-xl-6">
                <div className={`card`}>
                  <div className="card-body">
                    {loading ? (
                      <Loader />
                    ) : (
                      <form
                        className="forms-sample create-order-form"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <h5 className="mb-4 color-primary" style={{textTransform: 'none'}} >Создайте новою заявку
                          <FilePlus className="link-icon ms-2" style={{width: '18px'}}/>
                        </h5>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1" style={{fontSize: 14, color: '#fff'}}>
                            <b>Карта</b>
                          </label>
                          <input
                            type="text"
                            name="card"
                            onChange={hendler}
                            required
                            placeholder="XXXX-XXXX-XXXX-XXXX"
                            className="form-control"
                            id="exampleInputPassword1"
                            autoComplete="current-password"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1" style={{fontSize: 14, color: '#fff'}}>
                            <b>Сумма в RUB</b>
                          </label>
                          <input
                            type="text"
                            name="amount"
                            onChange={hendler}
                            placeholder="2000"
                            required
                            className="form-control"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{fontSize: 14, color: '#fff'}} htmlFor="bank">
                            <strong>Банк:</strong>
                          </label>
                          <input
                            type="text"
                            name="receiver_bank"
                            id="receiver_bank"
                            onChange={hendler}
                            required
                            placeholder="Введите банк"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{fontSize: 14, color: '#fff'}} htmlFor="bank">
                            <strong>UID:</strong>
                          </label>
                          <input
                            type="text"
                            name="external_id"
                            id="external_id"
                            onChange={hendler}
                            required
                            placeholder="Ид заявки"
                            className="form-control"
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className={`bg-success btn-line-white text-white px-4 py-2 mt-2 rounded-3`}
                            style={{ border: "none" }}
                            onClick={(e) => submit(e)}
                          >
                            Создать заявку{" "}
                            <Plus className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 grid-margin media-mt-3">
                <div className="card">
                  <div className="card-body" >
                    <h6 className="card-title-dark">
                      Создано заявок на сумму <CreditCard className="link-icon ms-2" style={{width: '18px'}}/>
                    </h6>
                    <p>(от начала смены)</p>
                    <hr className="mt-1 mb-3"/>
                    <h4  style={{fontSize: '18px'}} >
                      {!isEmpty(userCunsumer.orderTotals) && !isNaN(userCunsumer.orderTotals.total_rub) ? parseFloat(userCunsumer.orderTotals.total_rub).toFixed(0) : '0'} RUB
                    </h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 grid-margin">
                  <div className="card" > 
                    <div className="card-body">
                      <h6 className="card-title-dark">
                        Внутренний курс<DollarSign className="link-icon ms-2"  style={{width: '18px'}}/>
                      </h6>
                      <hr className="mt-2 mb-4"/>
                      <h4 style={{fontSize: '20px'}}> USDT/RUB  - 
                        {' '}{userCunsumer.currency ? userCunsumer.currency : '91,97'}
                      </h4>
                    </div>
                  </div>
                </div> */}
            </div>

            <div className="row">
              {!isEmpty(localStorage.getItem("startBallance")) && (
                <div className="col-12 col-md-6 mt-4">
                  <div className="card">
                    <div className="card-body">
                      <h5>Закрыть смену:</h5>
                      <p>Вы указали баланс на начало смены: {localStorage.getItem('startBallance')} руб.</p>
                      <div className="form-group">
                        <label htmlFor="startBallance" style={{fontSize: 14, color: '#fff'}}>
                          <b>Баланс на конец смены:</b>
                        </label>
                        <input
                          type="text"
                          name="endBallance"
                          onChange={hendler}
                          placeholder="введите сумму"
                          required
                          className="form-control"
                          id="endBallance"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="startBallance" style={{fontSize: 14, color: '#fff'}}>
                          <b>Скриншот остатка баланса:</b>
                        </label>
                        <input
                          type="file"
                          id="screenBalance"
                          name="screenBalance"
                          accept=".jpg,.png,.jpeg"
                          onChange={(event)=> { 
                            orderCunsumer.updateState('screenBalance', event.target.files);
                          }}
                          onClick={(event)=> { 
                            event.target.value = null
                          }}
                          className="form-control"
                        />
                      </div>
                      <button
                        className={`bg-primary text-white px-3 py-1 mt-2 rounded-3`}
                        style={{ border: "none", fontSize: 14 }}
                        onClick={() => endShift()}
                      >
                        Закончить смену
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHome;