import React, { useContext, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { isEmpty, isDate, filter } from "lodash";
import { Search, Sun, Moon, FilePlus, CloudOff, Users } from "react-feather";
import moment from "moment";
import { evaluate } from "mathjs";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import CreateRemain from "../../components/modals/CreateRemain";
import CreateMassRemain from "../../components/modals/CreateMassRemain";

import { RemainContext } from "../../context/remain-context";

const Remains = (props) => {
  registerLocale('ru', ru);
  
  const [loading, setLoading] = React.useState(false);
  const [showCreateRemain, setShowCreateRemain] = React.useState(false);
  const [showMassCreateRemain, setShowMassCreateRemain] = React.useState(false);
  const [hoveredWeek, setHoveredWeek] = React.useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const remainState = useContext(RemainContext);

  useEffect(() => {
    return function cleanup() {
      remainState.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getRemains = async () => {
    setLoading(true);

    if (isDate(remainState.start)) {
      await remainState.get()
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => { setLoading(false) });
    } else {
      sendNotification("warning", "Ошибка ⚠️", "Вы не выбрали дату.");
    }
  };


  const toggleEdit = async (id) => {
    if ( remainState.activeRemain === id) {
      setLoading(true);
      await remainState.updateRecord()
      .then(() => {
        setLoading(false);
      });
    } else {
      remainState.updateState('activeRemain', id);
    }
  };

  const updateData = () => {
    setTimeout( async () => {
      
      if (isDate(remainState.start)) {
        setLoading(true);
        remainState.clear();

        await remainState.get()
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => { setLoading(false) });
      }
    }, 100);
  };

  const togglePopUp = useCallback(
      (trigger) => {
        setShowCreateRemain(false);
        setShowMassCreateRemain(false);
        remainState.updateState('user_id', null);
        remainState.updateState('date', null);
        remainState.updateState('type', null);
        remainState.updateState('ballance', null);
        remainState.updateState('force', null);
        remainState.updateState('usersUpdateProcess', null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCreateRemain, showMassCreateRemain]);

    const selectWeekOnClick = (date) => {
      if (!date) return;
      const startOfWeek = moment(date).startOf('week').toDate();
      const endOfWeek = moment(date).endOf('week').toDate();
      remainState.updateState('start', startOfWeek);
      remainState.updateState('end', endOfWeek);
      
      setIsCalendarOpen(false);
    };

  const highlightWeekOnHover = (date) => {
    if (!date) return;
    const startOfWeek = moment(date).startOf('week');
    const endOfWeek = moment(date).endOf('week');
    
    const dates = [];
    let currentDay = startOfWeek.clone();
    
    while (currentDay.isSameOrBefore(endOfWeek, 'day')) {
      dates.push(new Date(currentDay.toDate()));
      currentDay.add(1, 'day');
    }
    
    setHoveredWeek(dates);
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Остатки по пользователям
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <CreateRemain
            updateData={() => updateData()}
            togglePopUp={togglePopUp}
            show={showCreateRemain}
          />
          <CreateMassRemain
            togglePopUp={togglePopUp}
            show={showMassCreateRemain}
          />
          <div className="page-content">
              <div className="row">
                <div className="col-md-3 border-end border-theme pe-4">
                  <div className="box">
                  <>
                    <style>
                      {`.week-highlight { background-color: rgba(66, 153, 225, 0.8) !important; }`}
                    </style>
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="dateRange">
                        <strong>Выберите неделю:</strong>
                      </label>
                      <DatePicker 
                        selectsRange
                        startDate={remainState.start ? new Date(remainState.start) : null}
                        endDate={remainState.end ? new Date(remainState.end) : null}
                        placeholderText="Выберите неделю"
                        wrapperClassName="form-control"
                        className="form-control week-selector"
                        calendarClassName="week-selector-calendar"
                        autoComplete="off"
                        onChange={(date) => selectWeekOnClick(date[0])}
                        onDayMouseEnter={highlightWeekOnHover}
                        highlightDates={hoveredWeek}
                        dayClassName={(date) => {
                          if (!date) return "";
                          return hoveredWeek.some(d => moment(d).isSame(date, 'day')) ? "week-highlight" : "";
                        }}
                        dateFormat='yyyy-MM-dd'
                        locale="ru"
                        id="dateRange"
                        open={isCalendarOpen}
                        onClickOutside={() => setIsCalendarOpen(false)}
                        onInputClick={() => setIsCalendarOpen(true)}
                      />
                    </div>
                  </>

                    {loading === true ? (
                      <button className="btn btn-line-info btn-small mt-4">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Загрузка...
                      </button>  
                    ) : (
                      <button
                        onClick={getRemains}
                        style={{ fontSize: '14px' }}
                        className={`bg-primary btn-line-white text-white p-2 mt-1 rounded-3 border-0 w-100`}
                      >
                        Получить <Search className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-md-3 border-end border-theme pe-4 ps-4">
                  <div className="box" style={{ minHeight: '162px' }}>
                    <h5 className="mb-2">Создать остаток:</h5>
                    <button
                      onClick={() => setShowCreateRemain(true)}
                      style={{ fontSize: '14px' }}
                      className={`bg-primary btn-line-white text-white p-2 mt-2 rounded-3 border-0 w-100`}
                    >
                      Создать остаток <FilePlus className="link-icon ms-1 mb-1" size={15} />
                    </button>
                    { !isEmpty(filter(remainState?.data?.remains, { type: 'END' })) ? (
                      <button
                        onClick={() => setShowMassCreateRemain(true)}
                        style={{ fontSize: '14px' }}
                        className={`bg-primary btn-line-white text-white p-2 mt-2 rounded-3 border-0 w-100`}
                      >
                        Создать массовый остаток <Users className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    ) : (
                      <button
                        style={{ fontSize: '14px' }}
                        className={`bg-gray btn-line-white text-gray p-2 mt-2 rounded-3 border-0 w-100`}
                        disabled={true}
                      >
                        Создать массовый остаток <Users className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>

                {
                  !isEmpty(remainState.totalsStart) && (
                    <div className="col-md-3 border-end border-theme pe-4 ps-4">
                      <div className="box-green" style={{ minHeight: '162px' }}>
                        <h4 className="mb-2">🏧 Доход:</h4>
                        <h3 className="mt-3">{ evaluate(`${remainState.totalsEnd["USDT"] || 0} - ${remainState.totalsStart["USDT"]}`).toFixed(2) } USDT</h3>
                        { isEmpty(filter(remainState.data.remains, { type: 'END' })) && (
                          <h6 className="text-gray mt-4">⚠️ Еще нету остатков вечер</h6>
                        )}
                      </div>
                    </div>
                  )
                }
              </div>
            
            

            { loading && 
              <div className="row mt-5">
                <Loader />
              </div>
            } 

            {!isEmpty(remainState.data) && !loading && 
              <>
                <hr />
                <h4 className="text-center mb-3">Остатки за неделю: {moment(remainState.start).format('DD')}-{moment(remainState.end).format('DD')} {moment(remainState.end).format('MMMM')}</h4>
                <div className="row">
                  {/* остатки утро */}
                  <div className="col-md-6">
                    <div className="box-theme">
                      <h5 className="mb-3"><Sun className="me-1 mb-1" size={18} /> Остатоки начала недели</h5>
                      <div className="table-responsive mb-2">
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Пользователь</th>
                              <th scope="col">Остаток</th>
                              <th scope="col">Изменить</th>
                            </tr>
                          </thead>
                          <tbody>
                          { filter(remainState.data.remains, { type: 'START' }).map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{item.username}</td>
                                <td>
                                  { remainState.activeRemain === item.id ? (
                                    <input 
                                      type="text" 
                                      value={remainState.remainBallance ? remainState.remainBallance : '' } 
                                      onChange={(e) => remainState.updateState('remainBallance', e.target.value)}
                                      style={{ width: '100px' }} 
                                    />
                                  ) : (
                                    `${item.ballance} ${item.currency}`
                                  )}
                                </td>
                                <td>
                                  <button
                                    onClick={() => toggleEdit(item.id)}
                                    style={{ fontSize: '12px' }}
                                    className={`btn-line-white px-3 py-1 rounded-3 border-0 ${remainState.activeRemain === item.id ? 'bg-primary text-light' : 'bg-light text-dark'}`}
                                  >
                                    { remainState.activeRemain === item.id ? 'Сохранить' : 'Изменить' }
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          {isEmpty(filter(remainState.data.remains, { type: 'START' })) && (
                              <tr>
                                <td colSpan="3" className="text-center text-muted" style={{ fontSize: '18px', padding: '20px' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        <CloudOff size={30} style={{ verticalAlign: 'middle', marginBottom: '8px' }} />
                                        <div>Еще нету данных в базе</div>
                                    </div>
                                </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      
                      { !isEmpty(remainState.totalsStart) && (
                        <>
                          {
                            Object.entries(remainState.totalsStart).map(([currency, total]) => (
                              <p className="mb-1 text-white" key={`start-${currency}`}><strong>Всего по валюте {currency}: {total.toFixed(2)}</strong></p>
                            ))
                          }
                        </>
                      )}
                    </div>
                  </div>

                  {/* остатки вечер  */}
                  <div className="col-md-6">
                    <div className="box-theme">
                      <h5 className="mb-3"><Moon className="me-1 mb-1" size={18} /> Остатоки конца недели</h5>
                      <div className="table-responsive mb-2">
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Пользователь</th>
                              <th scope="col">Остаток</th>
                              <th scope="col">Изменить</th>
                            </tr>
                          </thead>
                          <tbody>
                            { filter(remainState.data.remains, { type: 'END' }).map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.username}</td>
                                  <td>
                                    { remainState.activeRemain === item.id ? (
                                      <input 
                                        type="text" 
                                        value={remainState.remainBallance ? remainState.remainBallance : '' } 
                                        onChange={(e) => remainState.updateState('remainBallance', e.target.value)}
                                        style={{ width: '100px' }} 
                                      />
                                    ) : (
                                      `${item.ballance} ${item.currency}`
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => toggleEdit(item.id)}
                                      style={{ fontSize: '12px' }}
                                      className={`btn-line-white px-3 py-1 rounded-3 border-0 ${remainState.activeRemain === item.id ? 'bg-primary text-light' : 'bg-light text-dark'}`}
                                    >
                                      { remainState.activeRemain === item.id ? 'Сохранить' : 'Изменить' }
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            {isEmpty(filter(remainState.data.remains, { type: 'END' })) && (
                              <tr>
                                <td colSpan="3" className="text-center text-muted" style={{ fontSize: '18px', padding: '20px' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        <CloudOff size={30} style={{ verticalAlign: 'middle', marginBottom: '8px' }} />
                                        <div>Еще нету данных в базе</div>
                                    </div>
                                </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      
                      { !isEmpty(remainState.totalsEnd) && (
                        <>
                          {
                            Object.entries(remainState.totalsEnd).map(([currency, total]) => (
                              <p className="mb-1 text-white" key={`start-${currency}`}><strong>Всего по валюте {currency}: {total.toFixed(2)}</strong></p>
                            ))
                          }   
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Remains;