import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { FilePlus } from "react-feather";

import { RemainContext } from "../../context/remain-context";


const CreateRemain = (props) => {
  registerLocale('ru', ru);

  const { show } = props;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const remainState = useContext(RemainContext);
  
  useEffect(() => {
    async function fetchUsers() {
      await remainState.getUsers()
      .then((data) => {
        setLoading(false);
      });
    }
    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateField = async (event) => {
    await remainState.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setError(null);
    setLoading(true);
    await remainState.createRemain()
    .then((data) => {
      props.togglePopUp();
      setLoading(false);
    })
    .catch((error) => {
      setError(error.message);
      setLoading(false);
    });
  }

  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        style={{overflow: 'scroll'}}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content  p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Создание остатка для пользователя
              </h5>
              <button
                type="button"
                onClick={props.togglePopUp}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span style={{color: 'white'}} aria-hidden="true">&times;</span>
              </button>
            </div>
            {loading ? (
              <div className="placeholder-glow my-4 text-center">
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
                <div><span className="placeholder w-75 rounded-pill my-4" style={{ height: "40px" }} /></div>
              </div>
            ) : (
              <div className="modal-body text-white">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="user_id">
                        <strong>1. Выберите пользователя:</strong>
                      </label>
                      <div className="select-wrapper">
                        <select
                          onChange={updateField}
                          className="form-control"
                          name="user_id"
                          id="user_id"
                          value={remainState.user_id || ""}
                        >
                          <option value="" defaultValue>
                            Все пользователи
                          </option>
                          {remainState.users.map((user, index) => (
                            <option key={index} value={user.id}>
                              {user.fullname} - {user.currency}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> 


                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="date">
                        <strong>2. Выберите дату для остатка:</strong>
                      </label>
                      <DatePicker 
                        selected={remainState.date ? new Date(remainState.date) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        className="form-control"
                        autoComplete="off"
                        onChange={(date) => { remainState.updateState('date', date); }}
                        dateFormat='yyyy-MM-dd'
                        locale="ru"
                        id="date"
                        filterDate={(date) => {
                          const day = date.getDay();
                          // 1 - понедельник, 6 - суббота (0 - воскресенье)
                          return day === 1 || day === 6;
                        }}
                        dayClassName={(date) => {
                          const day = date.getDay();
                          return day === 1 ? 'monday-highlight' : day === 6 ? 'saturday-highlight' : undefined;
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="type">
                        <strong>3. Тип остатка:</strong>
                      </label>
                      <div className="select-wrapper">
                        <select
                          onChange={updateField}
                          className="form-control"
                          name="type"
                          id="type"
                          value={remainState.type || ""}
                        >
                          <option value="" defaultValue>Утро или вечер? </option>
                          <option value="START">Утро</option>
                          <option value="END">Вечер</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="ballance">
                        <strong>4. Баланс остатка:</strong>
                      </label>
                      <input
                        type="number"
                        onChange={updateField}
                        className="form-control"
                        name="ballance"
                        id="ballance"
                        placeholder="Введите сумму"
                        value={remainState.ballance || ""}
                      />
                    </div>

                    <div className="form-check form-check-flat form-check-primary my-4">
                      <label className={`form-check-label fw-bold ${remainState.force === true ? 'text-success' : 'text-white'}`} htmlFor="force">
                        <input 
                          type="checkbox" 
                          onChange={(e) => {
                            remainState.updateState(e.target.name, e.target.checked)
                          }} 
                          name="force" 
                          id="force" 
                          className="form-check-input"
                        />
                        принудительно (перезаписать если уже есть)
                        <i className="input-frame"></i>
                      </label>
                    </div>

                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            
            <div className="modal-footer pb-0">
              <button
                onClick={props.togglePopUp}
                type="button"
                data-dismiss="modal"
                style={{ fontSize: '14px' }}
                className={`bg-secondary btn-line-white text-white py-2 px-4 fw-bold mt-1 rounded-3 border-0`}
              >
                Закрыть
              </button>
              <button
                onClick={() => submit()}
                style={{ fontSize: '14px' }}
                className={`bg-success btn-line-white text-white py-2 px-5 fw-bold mt-1 rounded-3 border-0`}
              >
                Создать <FilePlus className="link-icon ms-1 mb-1" size={15} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRemain;