import React from "react";
import { Remain } from "../helpers/api";
import { sendNotification } from "../helpers/notification";
import moment from "moment";

export const RemainContext = React.createContext({});

export class RemainProvider extends React.Component {
  state = {
    updateState: async (key, val) =>
    await this.setState((state) => {
      state[key] = val;
      return state;
    }),

    get: async () => {
      return await Remain.getAll({ date: moment(this.state.start).utcOffset('+03').format("YYYY-MM-DD"), endDate: moment(this.state.end).format("YYYY-MM-DD") })
      .then((res) => {
        const data = res.body;
        // Подсчет суммы для type START
        const totalsStart = (data.remains || []).reduce((acc, item) => {
          if (item.type === "START") {
            const value = parseFloat(item.ballance) || 0;
            acc[item.currency] = (acc[item.currency] || 0) + value;
          }
          return acc;
        }, {});

        // Подсчет суммы для type END
        const totalsEnd = (data.remains || []).reduce((acc, item) => {
          if (item.type === "END") {
            const value = parseFloat(item.ballance) || 0;
            acc[item.currency] = (acc[item.currency] || 0) + value;
          }
          return acc;
        }, {});

        this.setState({ data, totalsStart, totalsEnd });
        sendNotification("success", "Успешно", "Остатки получены");
        return { data, totalsStart, totalsEnd };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    updateRecord: async () => {
      if (!this.state.remainBallance) {
        sendNotification("warning", "Ошибка ⚠️", "Введите новое значение баланса");
        return;
      }

      return await Remain.update(this.state.activeRemain, { ballance: this.state.remainBallance })
      .then(async (res) => {
        const data = res.body;

        this.setState({ activeRemain: null, remainBallance: null });
        await this.state.get();
        sendNotification("success", "Успешно", "Остаток обновлен");
        return { data };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));

        this.setState({ activeRemain: null, remainBallance: null });

        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);

        throw error;
      });
    },

    getUsers: async () => {
      return await Remain.getUsers()
      .then((res) => {
        const users = res.body;
        this.setState({ users });
        return { users };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    createRemain: async () => {
      if (!this.state.user_id || !this.state.date || !this.state.type || !this.state.ballance) {
        sendNotification("warning", "Ошибка ⚠️", "Заполните все поля");
        throw new Error("Заполните все поля");
      }

      return await Remain.create({
        user_id: this.state.user_id,
        date: moment(this.state.date).utcOffset('+03').format("YYYY-MM-DD"),
        type: this.state.type,
        ballance: this.state.ballance,
        force: this.state.force ? true : false,
      })
      .then(async (res) => {
        const data = res.body;

        this.setState({ user_id: null, date: null, type: null, ballance: null });
        await this.state.get();
        sendNotification("success", "Успешно", "Остаток создан");
        return { data };
      })
      .catch((error) => {
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw new Error(`${error.response?.body?.message}`);
      });
    },

    massCreateRemain: async (user_id, date, type, ballance) => {
      return await Remain.create({
        user_id: user_id,
        date: moment(date).utcOffset('+03').format("YYYY-MM-DD"),
        type: type,
        ballance: ballance
      })
      .then(async (res) => {
        const data = res.body;

        this.setState(prevState => ({
          usersUpdateProcess: {
            ...prevState.usersUpdateProcess,
            [user_id]: 'done'
          }
        }));
        return { data };
      })
      .catch((_) => {
        this.setState(prevState => ({
          usersUpdateProcess: {
            ...prevState.usersUpdateProcess,
            [user_id]: 'error'
          }
        }));
      });
    },

    setUserUpdateProcess: async (users) => {
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        this.setState(prevState => ({
          usersUpdateProcess: {
            ...prevState.usersUpdateProcess,
            [user.user_id]: 'loading'
          }
        }));
      }
    },

    clear: () => {
      this.setState({
        data: [],
        start: null,
        end: null,
        activeRemain: null,
        remainBallance: null,
        user_id: null,
        date: null,
        type: null,
        ballance: null,
        force: null,
        usersUpdateProcess: null,
      });
    },
  };

  render() {
    return (
      <RemainContext.Provider value={this.state}>
        {this.props.children}
      </RemainContext.Provider>
    );
  }
}
