import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { isEmpty, isDate } from "lodash";
import { Search, CloudOff, DollarSign } from "react-feather";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";

import { TransferContext } from "../../context/transfer-context";
import moment from "moment";
import { evaluate } from "mathjs";

const SummariesLive = (props) => {
  registerLocale('ru', ru);
  
  const [loading, setLoading] = React.useState(false);

  const state = useContext(TransferContext);

  useEffect(() => {

    return function cleanup() {
      state.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getRecords = async () => {
    setLoading(true);

    if (isDate(state.start)) {
      await state.getLiveSummaries()
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => { setLoading(false) });
    } else {
      setLoading(false);
      sendNotification("warning", "Ошибка ⚠️", "Вы не выбрали дату.");
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Остатки в реальном времени
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
              <div className="row">
                <div className="col-md-3 border-end border-theme pe-4">
                  <div className="box">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="start">
                        <strong>Выберите дату:</strong>
                      </label>
                      <DatePicker 
                        selected={state.start ? new Date(state.start) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        autoComplete="off"
                        className="form-control"
                        onChange={(date) => { state.updateState('start', date); }}
                        dateFormat='yyyy-MM-dd'
                        locale="ru"
                        id="start"
                      />
                    </div>

                    {loading === true ? (
                      <button className="btn btn-line-info btn-small mt-4">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Загрузка...
                      </button>  
                    ) : (
                      <button
                        onClick={getRecords}
                        style={{ fontSize: '14px' }}
                        className={`bg-primary btn-line-white text-white p-2 mt-1 rounded-3 border-0 w-100`}
                      >
                        Получить <Search className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            
            

            { loading && 
              <div className="row mt-5">
                <Loader />
              </div>
            } 

            {!isEmpty(state.summaries) && !loading && 
              <>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                      <h5 className="mb-3"><DollarSign className="me-1 mb-1" size={18} /> Остатки в реальном времени за дату {moment(state.start).format('DD-MM-YYYY')}</h5>
                      <div className="table-responsive mb-2">
                        <table className="table table-hover table-bordered border-white white">
                          <thead>
                            <tr className="border-white">
                              <th scope="col" className="border-secondary text-warning">Пользователь</th>
                              <th scope="col" className="border-secondary text-warning">Баланс сейчас</th>
                              <th scope="col" className="border-secondary text-white">Остаток начало недели</th>
                              <th scope="col" className="border-secondary text-white">Всего приходов</th>
                              <th scope="col" className="border-secondary text-white">Всего расходов</th>
                            </tr>
                          </thead>
                          <tbody>
                          { state.summaries.map((item) => {
                            return (
                              <tr key={item.username}>
                                <td className="border-secondary fw-bold bg-active-col"><span className="me-2">{item.online ? '🟢' : '⚪️'}</span>{item.username}</td>
                                <td className="border-secondary fw-bold bg-active-col">{item.real} {item.currency}</td>
                                <td className="border-secondary ">{item.start} {item.currency}</td>
                                <td className="border-secondary ">{item.transfers.income_total} {item.currency}</td>
                                <td className="border-secondary ">{evaluate(`${item.transfers.outcome_total || 0} + ${item.total_usdt || 0}`).toFixed(2)} {item.currency}</td>
                              </tr>
                            );
                          })}
                          {isEmpty(state.summaries) && (
                              <tr>
                                <td colSpan="3" className="text-center text-white" style={{ fontSize: '18px', padding: '20px' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        <CloudOff size={30} style={{ verticalAlign: 'middle', marginBottom: '8px' }} />
                                        <div>Еще нету данных в базе</div>
                                    </div>
                                </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SummariesLive;