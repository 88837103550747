import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import AdminBar from "../../components/AdminBar";
import { AdminContext } from "../../context/admin-context";

const UserEndDay = (props) => {
  const adminCunsumer = useContext(AdminContext);


  useEffect(() => {
    adminCunsumer.getUsersShortList();

    return function cleanup() {
      adminCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const endDayManager = async () => {
    return adminCunsumer.endManagerDay();
  };

  const handleData = async (e) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    await adminCunsumer.updateState(name, newValue);
  }

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Закончить смену менеджеру
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
              <>
                <h5 className="mt-4">Закончить смену менеджеру:</h5>
                <hr className="my-3" />
                <div className="row mt-1">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body p-3">
                        { !isEmpty(adminCunsumer.users_short_list) && (
                          <div className="form-group">
                            <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="manager_id">
                              <strong>Выберите менеджера:</strong>
                            </label>
                            <select
                              onChange={handleData}
                              className="form-control"
                              name="manager_id"
                              id="manager_id"
                              value={adminCunsumer.manager_id || ""}
                            >
                              <option value="" defaultValue>
                                Выберите менеджера
                              </option>
                              { adminCunsumer.users_short_list.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>{item.online ? '🟢' : '⚪️'} id: {item.id} - {item.fullname} ({item.username})</option>
                                )
                              })}
                            </select>
                          </div> 
                        )}

                        { !isEmpty(adminCunsumer.manager_id) && (
                          <>
                            <div className="form-group">
                              <label style={{ fontSize: 14, color:'#a4b4c3' }} htmlFor="remaining">
                                <strong>Остаток USDT на конец смены у менеджера:</strong>
                              </label>
                              <input
                                onChange={handleData}
                                className="form-control"
                                name="remaining"
                                id="remaining"
                                type="number"
                                required
                                value={adminCunsumer.remaining ? adminCunsumer.remaining : ""}
                              />
                            </div>

                            <div className="form-check form-check-flat form-check-primary my-4">
                              <label className={`form-check-label fw-bold ${adminCunsumer.force === true ? 'text-success' : 'text-white'}`} htmlFor="force">
                                <input type="checkbox" onChange={handleData} name="force" id="force" className="form-check-input" />
                                Пропустить все проверки сумм (перезаписать на текущие данные)
                                <i className="input-frame"></i>
                              </label>
                            </div>

                            <button
                              className="bg-success btn-line-white text-white px-3 py-2 rounded-3 border-0 w-100 fw-bold"
                              onClick={endDayManager}
                            >
                              Закончить смену для {adminCunsumer.users_short_list.find(item => item.id === adminCunsumer.manager_id)?.username}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  { !isEmpty(adminCunsumer.manager_id) && (
                    <div className="col-md-3">
                      <div className="card">
                        <div className="card-body p-3">
                          <h6 className="mb-2">Проверка данных:</h6>
                          <p className="mb-0">Начал смену с балансом: {adminCunsumer.users_short_list.find(item => item.id === adminCunsumer.manager_id)?.startBallance} руб.</p>
                          <p className="mb-0">Остаток вечер USDT: {adminCunsumer.remaining || "еще не указанно"} usdt</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEndDay;