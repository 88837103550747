import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { sendNotification } from "../../helpers/notification";
import UserBar from "../../components/UserBar";
import Loader from "../../components/Loader";
import UserFilterOrders from "./components/UserFilterOrders";
// import UserSumm from "./components/UserSumm";

import { OrderContext } from "../../context/orders-context";
import { AuthContext } from "../../context/auth-context";
import { UserContext } from "../../context/users-context";

import { FileText, AlignJustify} from "react-feather"; 

const getStatusBadge = (status) => {
  switch (status) {
    case "COMPLETED":
      return "btn-line-success"
    case "PENDING":
      return "btn-line-primary"
    case "ERROR":
      return "btn-line-danger"
    case "MARKED_AS_COMPLETED":
      return "btn-line-info"
    default:
      return "btn-line-warning"
  }
}

const getStatus = (status) => {
  switch (status) {
    case "COMPLETED":
      return "Выполненная"
    case "PENDING":
      return "В работе"
    case "ERROR":
      return "Ошибочная"
    case "MARKED_AS_COMPLETED":
      return "Выполнил трейдер"
    default:
      return "Новая"
  }
}

const UserHome = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(true);
  // const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const authCunsumer = useContext(AuthContext);
  const userCunsumer = useContext(UserContext);
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
    OrderCunsumer.getUserOrders(authCunsumer.user.id);
    userCunsumer.getUsersShortList();
    setLoading(false);

    return () => OrderCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]);

  let columns = [
    { name: "id",
      sortable: true,
      width: "80px",
      selector: (row) => row.id,
      cellExport: row => row.id,
    },
    { name: "Статус",
      sortable: true,
      width: "140px",
      selector: (row) => row.status,
      cellExport: row => row.status,
      cell: (row) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11 }}
            className={`btn-line ${getStatusBadge(row.status)}`}
          >
            {getStatus(row.status)}
          </button>
        );
      },
    },
    { name: "Карта",
      sortable: true,
      width: "250px",
      selector: (row) => row.card,
      cellExport: row => row.card,
      cell: (row) => row.card,
    },
    { name: "Cумма",
      sortable: true,
      width: "110px",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: "Банк",
      sortable: true,
      width: "140px",
      selector: (row) => row.bank,
      cellExport: (row) => row.bank,
      cell: (row) => {
        return `${row.bank}`;
      },
    },
    { ignoreRowClick: false,
      button: true,
      name: "Чек",
      width: "145px",
      cellExport: (row) => !isEmpty(row?.metadata?.check) ? "Чек есть" : "Нету",
      cell: (row) => {
        return (
          !isEmpty(row?.metadata?.check) &&
          <button
            className={`btn-line-white text-white px-3 py-2 border-0 rounded-3 ${row.metadata.downloaded === true ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => userCunsumer.getCheck(row.id, row.card).then(OrderCunsumer.getUserOrders(authCunsumer.user.id))}
          >
            Скачать чек
          </button>
        );
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      export: false,
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "Менеджер",
      selector: (row) => row.managerId,
      sortable: true,
      width: '100px',
      cellExport: (row) => row.managerId,
      cell: (row) => {
        return `${row.managerId}`;
      },
    },
    { name: "Дата создания",
      sortable: true,
      width: "195px",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Назначена в",
      sortable: true,
      width: "195px",
      selector: (row) => row.assignedAt,
      cellExport: (row) => row.assignedAt,
      cell: (row) => {
        return (
          <span>{moment(row.assignedAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      width: "195px",
      selector: (row) => row.successedAt,
      cellExport: (row) => row.successedAt,
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      sortable: true,
      width: "200px",
      cellExport: (row) => row.externalId,
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
    if (isEmpty(OrderCunsumer.orderArray)) {
      await setShowSearch(true);
    }
  }

  // const handleClearSelected = async (bool) => {
  //   await setClearOrderArray(true);
  //   await OrderCunsumer.updateState('orderArray', []);
  //   await setShowSearch(true);
  //   await setClearOrderArray(false);
  // }

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...',
    export: true,
    exportHeaders: true
  };

  return (
    <>
      {renderRedirect()}
      {loading && 
        <Loader/>
      }
      <div className="main-wrapper">
        <UserBar />
        <div className="page-wrapper"  style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Ваши заявки
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row  d-flex justify-content-start">
              <div className="col-12 col-xl-12">
                <div className="row">
                  <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <UserFilterOrders manager_list={userCunsumer.users_short_list} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    {/* <UserSumm handleClearSelected={() => handleClearSelected() } /> */}
                  </div>
                </div>
              </div>
              {isEmpty(OrderCunsumer.orders) ? (
                <div className="col-md-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title color-primary">Заявки
                        <FileText className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </h6>
                      <div className="text-center">
                        <h5 className="text-muted py-3" style={{textTransform: 'none'}}>Заявок пока нету...</h5>
                      </div>
                    </div>
                  </div>
                </div> 
              ) : (
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Заявки</h6>
                        <div className="table-responsive">
                          <div
                            id="dataTableExample_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <DataTableExtensions {...tableData} >
                              <DataTable
                                title={false}
                                columns={columns}
                                // data={OrderCunsumer.orders}
                                highlightOnHover={true}
                                striped={true}
                                pointerOnHover={true}
                                className={"table dataTable"}
                                pagination={true}
                                noHeader={true}
                                theme="solarized"
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[
                                  50,
                                  100,
                                  200,
                                  300,
                                  400,
                                ]}
                                selectableRowsComponent={Checkbox}
                                selectableRowsComponentProps={{ color: 'primary' }}
                                selectableRows={false}
                                selectableRowsVisibleOnly={true}
                                // clearSelectedRows={clearOrderArray}
                                selectableRowsHighlight={true}
                                onSelectedRowsChange={hendleSelected}
                              />
                            </DataTableExtensions>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHome;