import React, { useContext, useEffect } from "react";
import { AdminContext } from "../../context/admin-context";
import { OrderContext } from "../../context/orders-context";
import { sendNotification } from "../../helpers/notification";
import { isEmpty } from "lodash";
import Loader from "../Loader";


const PushToManagerModal = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState();

  const adminCunsumer = useContext(AdminContext);
  const OrderCunsumer = useContext(OrderContext);
  
  const { show } = props;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(adminCunsumer.activeOrderForManager)) {
      let order = OrderCunsumer.orders.filter((obj) => {
        return obj.id === adminCunsumer.activeOrderForManager;
      });
      setOrder(order[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCunsumer.activeOrderForManager]); 

  const updateField = async (event) => {
    await adminCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    if (isEmpty(adminCunsumer.manager_id)) {
      sendNotification("warning", "Ошибка", "Выберите менеджера для отправки заявки.");
      return;
    }

    return adminCunsumer
      .pushOrderToManager()
      .then(props.updateData())
      .then(simulateClick.current.click())
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  const simulateClick = React.useRef(null);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          style={{overflow: 'scroll'}}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content  p-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Отправить заявку менеджеру
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    adminCunsumer.updateState("manager_id", "");
                    props.togglePopUp();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span style={{color: 'white'}} aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-white">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                    { !isEmpty(props.manager_list) && (
                      <div className="form-group">
                        <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="manager_id">
                          <strong>Менеджер:</strong>
                        </label>
                        <select
                          onChange={updateField}
                          className="form-control"
                          name="manager_id"
                          id="manager_id"
                          value={adminCunsumer.manager_id || ""}
                        >
                          <option value="" defaultValue>
                            Выберите менеджера
                          </option>
                          { props.manager_list.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.online ? '🟢' : '⚪️'} id: {item.id} - {item.fullname} ({item.username})</option>
                            )
                          })}
                        </select>
                      </div> 
                    )}
                      
                      {!isEmpty(adminCunsumer.manager_id) && (
                        <h6 className="mt-3">Будем отправлять заявку: {order?.card} на суму {order?.amount} - менеджеру {props.manager_list.find(item => item.id === adminCunsumer.manager_id)?.fullname || 'Неизвестный менеджер'}</h6>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={() => {
                    adminCunsumer.updateState("manager_id", "");
                    props.togglePopUp();
                  }}
                  type="button"
                  className="btn btn-gray btn-small"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-green btn-small`}
                >
                  Отправить {props?.manager_list?.find(item => item.id === adminCunsumer.manager_id)?.fullname || ''}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PushToManagerModal;