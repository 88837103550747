import React, { createContext } from "react";
import { Admin } from "../helpers/api";
import { isEmpty, isNull } from "lodash";

import { sendNotification } from "../helpers/notification";

export const AdminContext = createContext();

export class AdminProvider extends React.Component {
  state = {
    getCorrections: async () => {
      await Admin.getCorrections()
        .then((res) => {
          this.setState({ corrections: res.body.records });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/",
            redirectMessage: error.response.body.message
              ? error.response.body.message
              : "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    // getSettings: async () => {
    //   await Admin.getSettings()
    //     .then((res) => {
    //       this.setState({ settings: res.body });
    //     })
    //     .catch((error) => {
    //       console.log("error", JSON.stringify(error, null, 4));
    //       this.setState({
    //         redirectPath: "/",
    //         redirectMessage: "Что то пошло не так. ⚠️",
    //         redirect: true,
    //       });
    //     });
    // },

    getUsersShortList: async () => {
      await Admin.getUsersShortList().then((res) => {
        this.setState({ users_short_list: res.body });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось загрузить пользователей для фильтра ⚠️", `${error.response?.body?.message}`);
      });
    },

    updateSettings: async () => {
      let params = {};
      if (!isNull(this.state.debtList)) params.debtList = this.state.debtList.split(",");
      params.comments = this.state.comments;
      params.externalId = this.state.externalId;
      params.dateAndTime = this.state.dateAndTime;
      params.sumAndComsa = this.state.sumAndComsa;
      await Admin.updateSettings(params).catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        this.setState({
          redirectPath: "/",
          redirectMessage: "Что то пошло не так. ⚠️",
          redirect: true,
        });
      });
    },

    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
    }),

    confirmTraderOrder: async (id, manager_id, status) => {
      await Admin.confirmTraderOrder({ id, manager_id, status}).then((res) => {
        if (status === 'ACCEPTED') {
          sendNotification("success", "Успешно", "Заявка подтверждена, и переведена в успех.");
        }
        if (status === 'DECLINED') {
          sendNotification("success", "Успешно", "Заявка отменена, и переведена в ошибку.");
        }
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось количество заявок трейдеров ⚠️", `${error.response?.body?.message}`);
      });
    },

    updateOrder: async () => {
      await Admin.updateOrder({ id: this.state.activeOrder, status: this.state.status }).then((res) => {
        sendNotification("success", "Успешно", "Заявка успешно получила новый статус.");
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },

    pushOrderToManager: async () => {
      await Admin.pushOrderToManager({ id: this.state.activeOrderForManager, manager_id: this.state.manager_id }).then((res) => {
        sendNotification("success", "Успешно", "Заявка отправлена менеджеру.");
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },

    endManagerDay: async () => {
      if (this.state.force !== true) {
        if (isEmpty(this.state.remaining)) {
          sendNotification("warning", "Введите сумму", "Введите сумму в usdt.");
          return;
        }
      }
        
      await Admin.endManagerDay({
        remaining: this.state.remaining,
        force: this.state.force,
        manager_id: this.state.manager_id,
      }).then((res) => {
        this.setState({ force: null, recived: null, remaining: null, dispute: null, manager_id: null });
        this.state.getUsersShortList();
        sendNotification("success", "Успешно", "Смена успешно завершена.");
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    sendToSP24: async (id) => {
      await Admin.sendToSP24({ id }).then((res) => {
        if (res.body.status === "success") {
          sendNotification("success", "Успешно", "Заявка отправлена в SP24 SBP.");
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },


    clear: () => {
      this.setState({
        corrections: [],
        settings: {},
        autoPayment: null,
        debtList: null,
        users_short_list: [],
        pending_withdraws_count: null,
        manager_id: null,
        recived: null,
        remaining: null,
        dispute: null,
        force: null,
      });
    },
    corrections: [],
    settings: {},
    rerenderSettings: false,
    debtList: null,
  };

  render() {
    return (
      <AdminContext.Provider value={this.state}>
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}

export const AdminConsumer = AdminContext.Consumer;
