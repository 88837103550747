import React from "react";
import { Transfer } from "../helpers/api";
import { sendNotification } from "../helpers/notification";
import moment from "moment";
import { sortBy, orderBy } from "lodash";

export const TransferContext = React.createContext({});

export class TransferProvider extends React.Component {
  state = {
    updateState: async (key, val) =>
    await this.setState((state) => {
      state[key] = val;
      return state;
    }),

    get: async () => {
      if (this.state.username) this.setState({ username: null });

      return await Transfer.getAll({ date: moment(this.state.start).utcOffset('+03').format("YYYY-MM-DD") })
      .then((res) => {
        const data = res.body;

        this.setState({ data });
        sendNotification("success", "Успешно", "Перемещения получены");
        return data;
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    getUserTransfers: async () => {
      return await Transfer.getUserRecords(this.state.userId, { date: moment(this.state.start).utcOffset('+03').format("YYYY-MM-DD") })
      .then((res) => {
        const data = res.body;

        this.setState({ data, userId: null, username: data.transfers.incomes[0]?.username || data.transfers.outcomes[0]?.username || null });
        sendNotification("success", "Успешно", "Перемещения пользователя получены");
        return data;
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    updateRecord: async () => {
      if (!this.state.amount) {
        sendNotification("warning", "Ошибка ⚠️", "Введите новое значение перемещения");
        return;
      }

      return await Transfer.update(this.state.transferId, { amount: this.state.amount })
      .then(async (res) => {
        const data = res.body;

        this.setState({ transferId: null, amount: null });
        await this.state.get();
        sendNotification("success", "Успешно", "Остаток обновлен");
        return { data };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));

        this.setState({ transferId: null, amount: null });

        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);

        throw error;
      });
    },

    getUsers: async () => {
      return await Transfer.getUsers()
      .then((res) => {
        const users = sortBy(res.body, 'currency');
        this.setState({ users });
        return { users };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    createTransfer: async () => {
      if (!this.state.user_id || !this.state.date || !this.state.type || !this.state.amount) {
        sendNotification("warning", "Ошибка ⚠️", "Заполните все поля");
        throw new Error("Заполните все поля");
      }

      return await Transfer.create({
        user_id: this.state.user_id,
        date: moment(this.state.date).utcOffset('+03').format("YYYY-MM-DD"),
        type: this.state.type,
        amount: this.state.amount,
        comment: this.state.comment,
      })
      .then(async (res) => {
        const data = res.body;

        this.setState({ user_id: null, date: null, type: null, amount: null, comment: null });
        await this.state.get();
        sendNotification("success", "Успешно", "Перемещение создано");
        return { data };
      })
      .catch((error) => {
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw new Error(`${error.response?.body?.message}`);
      });
    },

    getLiveSummaries: async () => {
      return await Transfer.getLiveSummaries({ date: moment(this.state.start).utcOffset('+03').format("YYYY-MM-DD") })
      .then((res) => {
        const data = orderBy(res.body, ['currency', 'real'], ['asc', 'desc']);
        this.setState({ summaries: data });
        return { data };
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
        throw error;
      });
    },

    clear: () => {
      this.setState({
        type: null,
        user_id: null,
        date: null,
        amount: null,
        username: null,
        userId: null,
        summaries: null,
        comment: null,
      });
    },
  };

  render() {
    return (
      <TransferContext.Provider value={this.state}>
        {this.props.children}
      </TransferContext.Provider>
    );
  }
}
