import React, { useContext, useEffect } from "react";
import { sendNotification } from "../helpers/notification";
import { AuthContext } from "../context/auth-context";

const Login = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [showTwoFa, setShowTwoFa] = React.useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    const authCunsumer = useContext(AuthContext);

    useEffect(() => {
        if (props.location.notify) {
            sendNotification(
                props.location.notify.type,
                props.location.notify.title,
                props.location.notify.message
            );
        }
    }, [props.location.notify]);

    const updateField = async (event) => {
        await authCunsumer.updateState(event.target.name, event.target.value);
    };

    const submit = async () => {
        return authCunsumer
            .login()
            .then((user) => {
                sendNotification(
                    "success",
                    "Привет 👋",
                    "Вы успешно авторизованы"
                );
                if (user.role === "admin") {
                    return props.history.push("/admin/");
                }
                if (user.role === "user") {
                    localStorage.removeItem("startBallance");
                    return props.history.push("/user/");
                }
                // if (user.role === "trader") {
                //     return props.history.push("/trader/");
                // }
                // if (user.role === "manager") {
                //     return props.history.push("/manager/");
                // }
            })
            .catch((e) => {
                if (e.response?.body?.message?.includes("2fa")) {
                    setShowTwoFa(true);
                    return sendNotification(
                        "warning",
                        "Ошибка",
                        "Bad 2fa code"
                    );
                }
                if (e?.response && e.response?.body?.message) {
                    sendNotification(
                        "danger",
                        "Ошибка ⚠️",
                        e.response.body.message
                    );
                }
            });
    };

    return (
        <>
            {loading && <p>Loading</p>}
            <section className="contact-area mh-100">
                <div className="container login-container" style={{maxWidth: '720px'}}>
                    <div className="contact-form-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center mb-70">
                                    <span className="sub-title">Apex Group</span>
                                    <h2 className="title mb-5 media-fs-20" style={{fontSize: '30px', textTransform: 'none'}}>
                                        Добро пожаловать!
                                        <svg className="p-1 mb-2 ms-2" width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_236_276)">
                                            <path d="M15.1465 27.6309C14.3868 27.6309 13.7695 28.2481 13.7695 29.0078C13.7695 29.7675 14.3868 30.3848 15.1465 30.3848C15.9062 30.3848 16.5234 29.7675 16.5234 29.0078C16.5234 28.2481 15.9062 27.6309 15.1465 27.6309Z" fill="#00C4F4"/>
                                            <path d="M26.1621 19.3691H4.13086C1.85301 19.3691 0 21.2222 0 23.5V40.0234C0 42.3013 1.85301 44.1543 4.13086 44.1543H26.1621C28.44 44.1543 30.293 42.3013 30.293 40.0234V23.5C30.293 21.2222 28.44 19.3691 26.1621 19.3691ZM16.5234 32.885V37.2695C16.5234 38.0306 15.9076 38.6465 15.1465 38.6465C14.3854 38.6465 13.7695 38.0306 13.7695 37.2695V32.885C12.1702 32.3147 11.0156 30.8006 11.0156 29.0078C11.0156 26.73 12.8686 24.877 15.1465 24.877C17.4243 24.877 19.2773 26.73 19.2773 29.0078C19.2773 30.8006 18.1227 32.3147 16.5234 32.885Z" fill="#564DCA"/>
                                            <path d="M42.8691 27.6309C41.0763 27.6309 39.5623 28.7855 38.9919 30.3848H33.0469V33.1387H38.9919C39.5623 34.738 41.0763 35.8926 42.8691 35.8926C45.147 35.8926 47 34.0396 47 31.7617C47 29.4839 45.147 27.6309 42.8691 27.6309Z" fill="#00C4F4"/>
                                            <path d="M42.8691 38.6465C42.1145 38.6465 41.4159 38.8649 40.8064 39.2197L38.4141 36.382C38.152 36.0714 37.7674 35.8926 37.3613 35.8926H33.0469V38.6465H36.7212L39.0057 41.3563C38.8415 41.8013 38.7383 42.2759 38.7383 42.7773C38.7383 45.0552 40.5913 47 42.8691 47C45.147 47 47 45.0552 47 42.7773C47 40.4995 45.147 38.6465 42.8691 38.6465Z" fill="#00C4F4"/>
                                            <path d="M42.8691 16.6152C40.5913 16.6152 38.7383 18.4682 38.7383 20.7461C38.7383 21.4149 38.9131 22.0377 39.1963 22.5971L36.8086 24.877H33.0469V27.6309H37.3613C37.715 27.6309 38.0565 27.4937 38.312 27.249L41.1814 24.5077C41.6979 24.7404 42.2668 24.877 42.8691 24.877C45.147 24.877 47 23.0239 47 20.7461C47 18.4682 45.147 16.6152 42.8691 16.6152Z" fill="#00C4F4"/>
                                            <path d="M15.1465 0C9.07256 0 4.13086 4.9417 4.13086 11.0156V16.6152H9.63867V11.0156C9.63867 7.97797 12.1088 5.50781 15.1465 5.50781C18.1841 5.50781 20.6543 7.97797 20.6543 11.0156V16.6152H26.1621V11.0156C26.1621 4.9417 21.2204 0 15.1465 0Z" fill="#564DCA"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_236_276">
                                            <rect width="47" height="47" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <br/>
                                        <span>Войдите</span> в свой аккаунт.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row ">
                                <div className="col-md-12 col-10 mx-auto">
                                    <div className="form-grp">
                                        <label className="label-login" htmlFor="exampleInputEmail1">
                                            Email адрес
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            onChange={
                                                updateField
                                            }
                                            placeholder="example@gmail.com"
                                            required
                                            className="form-control"
                                            id="exampleInputEmail1"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 col-10 mx-auto">
                                    <div className="form-grp">
                                        <label className="label-login" htmlFor="exampleInputPassword1">
                                            Пароль
                                        </label> 
                                        <input
                                            type="password"
                                            name="password"
                                            onChange={
                                                updateField
                                            }
                                            placeholder="**********"
                                            required
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            autoComplete="current-password"
                                        />
                                    </div>
                                </div>
                                {showTwoFa && (
                                    <div className="col-md-12 col-10 mx-auto">
                                        <div className="form-grp">
                                            <label className="label-login" htmlFor="code">
                                                2FA
                                            </label>
                                            <input
                                                type="text"
                                                name="code"
                                                onChange={
                                                    updateField
                                                }
                                                placeholder="*******"
                                                required
                                                className="form-control"
                                                id="code"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="submit-btn text-center">
                                <button 
                                    type="submit" 
                                    className="btn btn-one submitform login-btn"
                                    onClick={(e) => submit(e)}
                                >
                                    Войти
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;