import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { isEmpty, orderBy, isDate } from "lodash";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { UserContext } from "../../context/users-context";
import {AlignJustify, Box, DollarSign, RefreshCw, User, UserCheck} from "react-feather";
import { Search } from "react-feather";
import { sendNotification } from "../../helpers/notification";

const UsersReport = (props) => {
  
  const [loading, setLoading] = React.useState(false);

  registerLocale('ru', ru);
  const userCunsumer = useContext(UserContext);

  useEffect(() => {
    return function cleanup() {
      userCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const submit = async (e, current) => {
    if (current) {
      return userCunsumer.getUsersReport(null, null, current)
      .then(() => setLoading(false));
    }

    if (isDate(userCunsumer.start) && isDate(userCunsumer.end)) {
      return userCunsumer.getUsersReport(userCunsumer.start, userCunsumer.end, null)
      .then(() => setLoading(false));
    } else {
      sendNotification("warning", "Ошибка ⚠️", `Вы не выбрали даты`);
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Свод кассы
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-md-3 border-end border-theme pe-4">
                <div className="box">
                  <div className="form-group">
                    <label style={{ fontSize: 16, color:'#fff' }}>
                      <strong>Начиная с даты:</strong>
                    </label>
                    <DatePicker 
                      selected={userCunsumer.start ? new Date(userCunsumer.start) : null}
                      placeholder="Выберите дату"
                      wrapperClassName="form-control"
                      className="form-control"
                      onChange={(date) => {
                        userCunsumer.updateState('start', date);
                      }}
                      dateFormat='yyyy-MM-dd'
                      locale="ru"
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ fontSize: 16, color:'#fff' }}>
                      <strong>До даты 🏁:</strong>
                    </label>
                    <DatePicker 
                      selected={userCunsumer.end ? new Date(userCunsumer.end) : null}
                      placeholder="Выберите дату"
                      wrapperClassName="form-control"
                      className="form-control"
                      onChange={(date) => {
                        userCunsumer.updateState('end', date)
                      }}
                      dateFormat='yyyy-MM-dd'
                      locale="ru"
                    />
                  </div>

                  {loading === true ? (
                    <button className="btn btn-line-info btn-small mt-4">
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Загрузка...
                    </button>  
                  ) : (
                    <button
                      onClick={(e) => submit(e, false)}
                      style={{ fontSize: '14px' }}
                      className={`bg-success btn-line-white text-white fw-bold px-3 py-2 mt-2 w-100 rounded-3 border-0`}
                    >
                      Получить <Search className="link-icon ms-1 mb-1" size={15} />
                    </button>
                  )}
                </div>
              </div>

              {!isEmpty(userCunsumer.report) && (
                <div className="col-md-9 ps-4">
                  <div className="box d-flex py-2" style={{height: '250px'}}>
                    <div className="text-center border-end border-theme px-4 py-5">
                      <Box size={40} className="text-white mb-4" />
                      <h5 className="mb-2">Общий доход:</h5>
                      <h5>{userCunsumer.report.stats.profit} usdt</h5>
                    </div>
                    <div className="text-center border-end border-theme px-4 py-5">
                      <User size={40} className="text-white mb-4" />
                      <h5 className="mb-2">Расход З/П RUB:</h5>
                      <h5>{userCunsumer.report.stats.sallary_totals} руб.</h5>
                    </div>
                    <div className="text-center border-end border-theme px-4 py-5">
                      <UserCheck size={40} className="text-white mb-4" />
                      <h5 className="mb-2">	Расход З/П USDT:</h5>
                      <h5>{userCunsumer.report.stats.sallary_totals_usdt} usdt</h5>
                    </div>
                    <div className="text-center border-end border-theme px-4 py-5">
                      <RefreshCw size={40} className="text-white mb-4" />
                      <h5 className="mb-2">Средний Курс:</h5>
                      <h5>{userCunsumer.report.stats.course} руб.</h5>
                    </div>
                    <div className="text-center border-end border-theme px-4 py-5">
                      <DollarSign size={40} className="text-success mb-4" />
                      <h5 className="mb-2 text-success">Чистый доход:</h5>
                      <h5 className="text-success">{userCunsumer.report.stats.clear_profit} usdt</h5>
                    </div>
                  </div>
                </div>
              )}
            </div>

            { loading && 
              <div className="row mt-5">
                <Loader />
              </div>
            } 

            {!isEmpty(userCunsumer.report) && (
              <>
                {/* <h5 className="mt-4">Доход за смену</h5>
                <hr className="my-3" />
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-3">
                        <div className="table-responsive">
                          <table className="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Общий доход</th>
                                <th scope="col">Расход З/П руб</th>
                                <th scope="col">Расход З/П USDT</th>
                                <th scope="col">Курс</th>
                                <th scope="col">Доход</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><strong>{userCunsumer.report.stats.profit} usdt</strong></td>
                                <td><strong>{userCunsumer.report.stats.sallary_totals} руб</strong></td>
                                <td><strong>{userCunsumer.report.stats.sallary_totals_usdt} usdt</strong></td>
                                <td><strong>{userCunsumer.report.stats.course}</strong></td>
                                <td><strong style={{color: 'greenyellow'}}>{userCunsumer.report.stats.clear_profit} usdt</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <h5 className="mt-4">Сводка по юзерам</h5>
                <hr className="my-3" />
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-3">
                        <div className="table-responsive">
                          <table className="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Выплачено всего (руб.)</th>
                                <th scope="col">Получено USDT</th>
                                <th scope="col">Количество заявок</th>
                              </tr>
                            </thead>
                            <tbody>
                              { orderBy(userCunsumer.report.users, ['amount'], ['desc']).map((item, index) => {
                                return (
                                  <tr key={item.email}>
                                    <td>{item.email}</td>
                                    <td>{parseFloat(item.total_rub).toFixed(2)} руб.</td>
                                    <td>{parseFloat(item.total_usdt).toFixed(2)} usdt</td>
                                    <td>{item.count} шт.</td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td><strong>Всего по юзерам:</strong></td>
                                <td><strong>{userCunsumer.report.users.reduce((total, item) => total + item.total_rub, 0)} руб.</strong></td>
                                <td><strong>{userCunsumer.report.users.reduce((total, item) => total + item.total_usdt, 0)} usdt</strong></td>
                                <td><strong>{userCunsumer.report.users.reduce((total, item) => total + parseFloat(item.count), 0)} шт.</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="mt-4">Сводка по менеджерам:</h5>
                <hr className="my-3" />
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-3">
                        <div className="table-responsive">
                          <table className="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">username</th>
                                <th scope="col">Выплачено всего (руб.)</th>
                                <th scope="col">Получено USDT</th>
                                <th scope="col">Количество заявок</th>
                              </tr>
                            </thead>
                            <tbody>
                              { orderBy(userCunsumer.report.managers, ['amount'], ['desc']).map((item, index) => {
                                return (
                                  <tr key={item.username}>
                                    <td>{item.username}</td>
                                    <td>{parseFloat(item.total_rub).toFixed(2)} руб.</td>
                                    <td>{parseFloat(item.total_usdt).toFixed(2)} usdt</td>
                                    <td>{item.count} шт.</td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td><strong>Всего по менеджерам:</strong></td>
                                <td><strong>{userCunsumer.report.managers.reduce((total, item) => total + item.total_rub, 0).toFixed(2)} руб.</strong></td>
                                <td><strong>{userCunsumer.report.managers.reduce((total, item) => total + item.total_usdt, 0).toFixed(2)} usdt</strong></td>
                                <td><strong>{userCunsumer.report.managers.reduce((total, item) => total + parseFloat(item.count), 0)} шт.</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersReport;