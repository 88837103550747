import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import  secureLocalStorage  from  "react-secure-storage";
import { UserContext } from "../context/users-context";
import { sendNotification } from "../helpers/notification";

import {
  List,
  Book,
  Settings,
  Users,
  LogOut,
  Database,
  Archive,
  ChevronsLeft,
  DollarSign,
  Flag,
  Tag,
  GitPullRequest,
  Video,
} from "react-feather";

import { Link as LinkIcon } from "react-feather";

const AdminBar = (props) => {

  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    userCunsumer.userMe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    Cookies.remove("jwt");
    secureLocalStorage.removeItem("role");
    sendNotification("success", "Вы выйшли из системы.");
    window.location.href = "/auth/login";
  };

  const renderActiveClass = (type) => {
    if (window.location.pathname === type) {
      return "active";
    }
  };

  return (
    <>
      <div className={`sidebar-dark ${userCunsumer.sidebarOpen === true && 'sidebar-open'}`}>
        <nav className="sidebar">
          <div className="sidebar-header">
            <Link to="/admin/"  className="sidebar-brand" style={{width:'260px'}}
             onClick = {() => {
              if (window.innerWidth <= 768) {
                userCunsumer.setSidebarOpen(false)
              }
            }}>
              <img src={require("../images/logo2.png")} className="w-50" alt="" />
            </Link>
            <ChevronsLeft className="link-icon icon-menu-mobil-media" style={{width: '30px'}} onClick = { () => {userCunsumer.setSidebarOpen(false)}}/>
          </div>
          <div className="sidebar-body">
            <ul className="nav" style={{paddingLeft: '15px'}}>
              <li className="nav-item nav-category">Заявки</li>

              <li className={`nav-item ${renderActiveClass("/admin/last-orders")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/last-orders" className="nav-link">
                  <List className="link-icon" />
                  <span className="link-title">Последние заявки (1к)</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/archive")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/archive" className="nav-link">
                  <Archive className="link-icon" />
                  <span className="link-title">Архив (Все заявки)</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/orders/sendcallback")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/orders/sendcallback" className="nav-link">
                  <LinkIcon className="link-icon" />
                  <span className="link-title">Отослать коллбек</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/admin/orders/export")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/orders/export" className="nav-link">
                  <Database className="link-icon" />
                  <span className="link-title">Экспорт заявок</span>
                </Link>
              </li>

              <li className="nav-item nav-category">Пользователи</li>

              <li className={`nav-item ${renderActiveClass("/admin/users")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/users" className="nav-link">
                  <Users className="link-icon" />
                  <span className="link-title">Пользователи</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/corrections")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/corrections" className="nav-link">
                  <Book className="link-icon" />
                  <span className="link-title">История корректировок</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/manager/close")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/manager/close" className="nav-link">
                  <Flag className="link-icon" />
                  <span className="link-title">Мереджер закончить смену</span>
                </Link>
              </li>

              <li className="nav-item nav-category">Статистика</li>

              <li className={`nav-item ${renderActiveClass("/admin/allusers/report")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/allusers/report" className="nav-link">
                  <DollarSign className="link-icon" />
                  <span className="link-title">Сводка</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/allusers/stats")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/allusers/stats" className="nav-link">
                  <DollarSign className="link-icon" />
                  <span className="link-title">Суммы пользователей</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/remains")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/remains" className="nav-link">
                  <Tag className="link-icon" />
                  <span className="link-title">Остатки</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/transfers")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/transfers" className="nav-link">
                  <GitPullRequest className="link-icon" />
                  <span className="link-title">Перемещения</span>
                </Link>
              </li>

              <li className={`nav-item ${renderActiveClass("/admin/summaries/live")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/summaries/live" className="nav-link">
                  <Video className="link-icon" />
                  <span className="link-title">В реальном времени</span>
                </Link>
              </li>
              
              <li className="nav-item nav-category">Другое</li>
              
              <li className={`nav-item ${renderActiveClass("/admin/user-settings")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/admin/user-settings" className="nav-link">
                  <Settings className="link-icon" />
                  <span className="link-title">Настройки</span>
                </Link>
              </li>
              <li className="nav-item">
                <span
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={logout}
                >
                  <LogOut className="link-icon" />
                  <span className="link-title">Выход</span>
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default AdminBar;
