import React, { useContext, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { isEmpty, isDate } from "lodash";
import { Search, CloudOff, GitPullRequest, LogIn, LogOut } from "react-feather";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import CreateTransfer from "../../components/modals/CreateTransfer";

import { TransferContext } from "../../context/transfer-context";
import moment from "moment";

const Transfers = (props) => {
  registerLocale('ru', ru);
  
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const state = useContext(TransferContext);

  useEffect(() => {
    async function fetchUsers() {
      await state.getUsers()
      .then((data) => {
        setLoading(false);
      });
    }
    fetchUsers();

    return function cleanup() {
      state.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getRecords = async () => {
    setLoading(true);

    if (isDate(state.start)) {
      await state.get()
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => { setLoading(false) });
    } else {
      setLoading(false);
      sendNotification("warning", "Ошибка ⚠️", "Вы не выбрали дату.");
    }
  };

  const getUserRecords = async () => {
    setLoading(true);

    if (isDate(state.start) && !isEmpty(state.userId)) {
      await state.getUserTransfers()
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => { setLoading(false) });
    } else {
      setLoading(false);
      sendNotification("warning", "Ошибка ⚠️", "Вы не выбрали дату или пользователя.");
    }
  };

  const toggleEdit = async (id) => {
    if ( state.transferId === id) {
      setLoading(true);
      await state.updateRecord()
      .then(() => {
        setLoading(false);
      });
    } else {
      state.updateState('transferId', id);
    }
  };

  const updateData = () => {
    setTimeout( async () => {
      
      if (isDate(state.start)) {
        setLoading(true);
        state.clear();

        await state.get()
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => { setLoading(false) });
      }
    }, 100);
  };

  const togglePopUp = useCallback(
      (trigger) => {
        setShowModal(false);
        state.updateState('type', null);
        state.updateState('user_id', null);
        state.updateState('date', null);
        state.updateState('amount', null);
        state.updateState('comment', null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Перемещения денежных средств
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <CreateTransfer
            updateData={() => updateData()}
            togglePopUp={togglePopUp}
            show={showModal}
          />
          <div className="page-content">
              <div className="row">
                <div className="col-md-3 border-end border-theme pe-4">
                  <div className="box">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color:'#fff' }} htmlFor="start">
                        <strong>По дате: (все пользовательи)</strong>
                      </label>
                      <DatePicker 
                        selected={state.start ? new Date(state.start) : null}
                        placeholder="Выберите дату"
                        wrapperClassName="form-control"
                        autoComplete="off"
                        className="form-control"
                        onChange={(date) => { state.updateState('start', date); }}
                        dateFormat='yyyy-MM-dd'
                        locale="ru"
                        id="start"
                      />
                    </div>

                    {loading === true ? (
                      <button className="btn btn-line-info btn-small mt-4">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Загрузка...
                      </button>  
                    ) : (
                      <button
                        onClick={getRecords}
                        style={{ fontSize: '14px' }}
                        className={`bg-primary btn-line-white text-white p-2 mt-1 rounded-3 border-0 w-100`}
                      >
                        Получить <Search className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-md-3 border-end border-theme px-4">
                  <div className="box">
                    <div className="form-group">
                      <label style={{ fontSize: 16, color: "#fff" }} htmlFor="user_transfers">
                        <strong>По пользователью:</strong>
                      </label>
                      <div className="select-wrapper">
                        <select
                          onChange={(e) => state.updateState("userId", e.target.value)}
                          className="form-control"
                          name="user_transfers"
                          id="user_transfers"
                          value={state.user_id || ""}
                        >
                          <option value="">Все пользователи</option>
                          {state?.users && state?.users.map((user, index) => (
                            <option key={index} value={user.id}>
                              {user.fullname} - {user.currency}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {!isEmpty(state.userId) && (
                      <div className="form-group">
                        <label style={{ fontSize: 16, color:'#fff' }} htmlFor="userDate">
                          <strong>За дату:</strong>
                        </label>
                        <DatePicker 
                          selected={state.start ? new Date(state.start) : null}
                          placeholder="Выберите дату"
                          wrapperClassName="form-control"
                          autoComplete="off"
                          className="form-control"
                          onChange={(date) => { state.updateState('start', date); }}
                          dateFormat='yyyy-MM-dd'
                          locale="ru"
                          id="userDate"
                        />
                      </div>
                    )}

                    {loading === true ? (
                      <button className="btn btn-line-info btn-small mt-4">
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Загрузка...
                      </button>  
                    ) : (
                      <button
                        onClick={getUserRecords}
                        style={{ fontSize: '14px' }}
                        className={`bg-primary btn-line-white text-white p-2 mt-1 rounded-3 border-0 w-100`}
                      >
                        Получить <Search className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-md-3 border-end border-theme px-4">
                  <div className="box">
                    <h5 className="mb-2">Создать новое перемещение денежных средств</h5>
                    <button
                      onClick={() => setShowModal(true)}
                      style={{ fontSize: '14px' }}
                      className={`bg-success btn-line-white text-white p-2 mt-4 rounded-3 border-0 w-100 mb-2`}
                    >
                      Создать перемещение <GitPullRequest className="link-icon ms-1 mb-1" size={15} />
                    </button>
                  </div>
                </div>
              </div>
            
            

            { loading && 
              <div className="row mt-5">
                <Loader />
              </div>
            } 

            {!isEmpty(state.data) && !loading && 
              <>
                <hr />
                <h4 className="text-center mb-3">
                  Перемещения средств 
                  {!isEmpty(state.username) ? 
                    <span className="text-warning"> у {state.username}</span> :
                    <span className="text-primary"> по всем</span>
                  } за {moment(state.start).format('DD-MM-YYYY')}</h4>
                <div className="row">
                  {/* остатки утро */}
                  <div className="col-md-6">
                    <div className="box-green">
                      <h5 className="mb-3 text-green"><LogIn className="me-1 mb-1" size={18} /> Приходы</h5>
                      <div className="table-responsive mb-2">
                        <table className="table smart table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Пользователь</th>
                              <th scope="col">Сумма прихода</th>
                              <th scope="col">Изменить</th>
                            </tr>
                          </thead>
                          <tbody>
                          { state.data.transfers.incomes.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="fw-bold">{item.username}</td>
                                <td className="fw-bold">
                                  { state.transferId === item.id ? (
                                    <input 
                                      type="text" 
                                      value={state.amount ? state.amount : '' } 
                                      onChange={(e) => state.updateState('amount', e.target.value)}
                                      style={{ width: '100px' }} 
                                    />
                                  ) : (
                                    `${item.amount} ${item.currency}`
                                  )}
                                  <p className="mb-0 text-white fs-14">{item?.metadata?.comment ? item.metadata.comment : 'Без коммента'}</p>
                                </td>
                                <td>
                                  <button
                                    onClick={() => toggleEdit(item.id)}
                                    style={{ fontSize: '12px' }}
                                    className={`btn-line-white px-3 py-1 rounded-3 border-0 ${state.transferId === item.id ? 'bg-primary text-light' : 'bg-light text-dark'}`}
                                  >
                                    { state.transferId === item.id ? 'Сохранить' : 'Изменить' }
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          {isEmpty(state.data.transfers.incomes) && (
                              <tr>
                                <td colSpan="3" className="text-center text-white" style={{ fontSize: '18px', padding: '20px' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        <CloudOff size={30} style={{ verticalAlign: 'middle', marginBottom: '8px' }} />
                                        <div>Еще нету данных в базе</div>
                                    </div>
                                </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      
                      { !isEmpty(state.data.total_income) && (
                        <>
                          {
                            Object.entries(state.data.total_income).map(([currency, total]) => (
                              <p className="mb-1 text-white" key={`income-${currency}`}><strong>Всего приходов в {currency}: {total.toFixed(2)}</strong></p>
                            ))
                          }
                        </>
                      )}
                    </div>
                  </div>

                  {/* остатки вечер  */}
                  <div className="col-md-6">
                    <div className="box-red">
                      <h5 className="mb-3 text-danger"><LogOut className="me-1 mb-1" size={18} /> Расходы</h5>
                      <div className="table-responsive mb-2">
                        <table className="table smart table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Пользователь</th>
                              <th scope="col">Сумма расхода</th>
                              <th scope="col">Изменить</th>
                            </tr>
                          </thead>
                          <tbody>
                            { state.data.transfers.outcomes.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td className="fw-bold">{item.username}</td>
                                  <td className="fw-bold">
                                    { state.transferId === item.id ? (
                                      <input 
                                        type="text" 
                                        value={state.amount ? state.amount : '' } 
                                        onChange={(e) => state.updateState('amount', e.target.value)}
                                        style={{ width: '100px' }} 
                                      />
                                    ) : (
                                      `${item.amount} ${item.currency}`
                                    )}
                                    <p className="mb-0 text-white fs-14">{item?.metadata?.comment ? item.metadata.comment : 'Без коммента'}</p>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => toggleEdit(item.id)}
                                      style={{ fontSize: '12px' }}
                                      className={`btn-line-white px-3 py-1 rounded-3 border-0 ${state.transferId === item.id ? 'bg-primary text-light' : 'bg-light text-dark'}`}
                                    >
                                      { state.transferId === item.id ? 'Сохранить' : 'Изменить' }
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            {isEmpty(state.data.transfers.outcomes) && (
                              <tr>
                                <td colSpan="3" className="text-center text-white" style={{ fontSize: '18px', padding: '20px' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        <CloudOff size={30} style={{ verticalAlign: 'middle', marginBottom: '8px' }} />
                                        <div>Еще нету данных в базе</div>
                                    </div>
                                </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      
                      { !isEmpty(state.data.total_outcome) && (
                        <>
                          {
                            Object.entries(state.data.total_outcome).map(([currency, total]) => (
                              <p className="mb-1 text-white" key={`start-${currency}`}><strong>Всего расходов в {currency}: {total.toFixed(2)}</strong></p>
                            ))
                          }   
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Transfers;